/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  DataProvider,
  IAppLocationState,
  ReduxStoreConfigurator,
} from "@bms/common";
import {
  BrowserHistoryBuildOptions,
  createBrowserHistory,
  History,
} from "history";

import {
  firebaseInterfaceInstance,
  firebasePromise,
  TFirebaseInterface,
} from "./firebase";
import { InitializeTagManager } from "./gtm";
import "./i18n";
import { withSentry } from "./sentry";

InitializeTagManager();

// Set up HttpFactory
DataProvider.initHttpFactory?.();

export const browserHistoryOptions: BrowserHistoryBuildOptions = {};

if (process.env.PUBLIC_URL) {
  browserHistoryOptions.basename = process.env.PUBLIC_URL;
}

export const appHistory: History<IAppLocationState> = createBrowserHistory(
  browserHistoryOptions,
);

export const reduxStoreConfigurator = new ReduxStoreConfigurator(appHistory);
export const appStore = reduxStoreConfigurator.initStore();

export {
  firebasePromise,
  firebaseInterfaceInstance,
  type TFirebaseInterface,
  withSentry,
};
