/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useNotifications } from "@bms/common";
import React from "react";

import { useFirebaseNotifications } from "hooks/useFirebaseNotifications";

import "./App.scss";

const App = ({ children }: { children?: React.ReactNode }) => {
  useNotifications();
  useFirebaseNotifications();
  return <main className="AppContainer">{children}</main>;
};

export default App;
