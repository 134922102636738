/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { goBack, push, replace } from "connected-react-router";
import { LocationDescriptorObject, LocationState } from "history";
import { match as RouterMatch } from "react-router";

import { ROUTES } from "../constants";
import {
  ActionType,
  CellType,
  MediaStreamType,
  MediaType,
  ScreenType,
} from "../enums";
import { Identifier, IMediaModel } from "../models";
import { AudioStore, dispatch } from "../store";

import { UrlHelper } from "./url.helper";

const createRouteState = (obj: any = {}) => ({
  ...obj,
  from: window.location?.pathname,
});

export class RouteHelper {
  static getDetailsPath(media: IMediaModel) {
    let path: string;
    switch (media.MediaTypeCode) {
      case MediaType.Album:
        path = `${ROUTES.ALBUM_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Podcast:
        path = `${ROUTES.PODCAST_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Series:
        path = `${ROUTES.SERIES_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Package:
        path = `${ROUTES.PACKAGE_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Article:
        path = `${ROUTES.ARTICLE_SCREEN}/${media.Id}`;
        break;
      case MediaType.Club:
        path = `${ROUTES.CLUB_DETAILS_SCREEN}/${media.Id}/${media.Title}`;
        break;
      case MediaType.Season:
        path = `${ROUTES.SEASON_DETAILS_SCREEN}/${media.Id}/${media.Title}`;
        break;
      case MediaType.Person:
        path = `${ROUTES.PERSON_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Competitions:
        // TODO - MOTTO - This is hardcoded due to faster development time, and it's pointing to chosen hardcoded ID
        path = `${ROUTES.PLAYLIST_SCREEN}/3/${CellType.Round}/Clubs`;
        break;
      default:
        path = `${ROUTES.MOVIE_DETAILS_SCREEN}/${media.Id}`;
        break;
    }

    return path;
  }

  static goTo(path: string, replaceRoute?: boolean) {
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToDetails(media?: IMediaModel, replaceRoute?: boolean): void {
    if (!media) {
      return;
    }

    let path = "";
    const queryParams: { [key: string]: string | number | boolean } = {};
    if (media.CustomMetadata?.Action) {
      const action = media.CustomMetadata?.Action;

      switch (action.ActionType) {
        case ActionType.OpenScreen:
          if (
            action.ScreenTypeCode &&
            action.ScreenTypeCode !== ScreenType.Custom
          ) {
            path = `/${action.ScreenTypeCode.toLowerCase().replace("_", "-")}`;

            if (action.ScreenId) {
              path += `/${action.ScreenId}`;
            }
          } else {
            path = `/${action.ScreenId}`;
          }

          break;
        case ActionType.OpenUrl:
          const url = action.Url;
          if (url) {
            window.open(url, "_blank");

            return;
          }

          break;
        default:
          // Advertisement media doesn't have default action
          if (media.MediaTypeCode === MediaType.Advertisement) {
            return;
          }

          break;
      }
    } else {
      // Advertisement media doesn't have default action
      if (media.MediaTypeCode === MediaType.Advertisement) {
        return;
      }

      path = RouteHelper.getDetailsPath(media);
    }

    const location: LocationDescriptorObject = {
      pathname: path,
      state: createRouteState({
        media,
      }),
    };

    location.search = UrlHelper.stringify(queryParams);

    dispatch(replaceRoute ? replace(location) : push(location));
  }

  static goToPlayer<S = LocationState>(
    media: IMediaModel,
    replaceRoute?: boolean,
    routeState?: S,
  ): void {
    let queryParams: { [key: string]: string | number | boolean } = {};

    if (media.Action?.QueryParams) {
      queryParams = {
        ...queryParams,
        ...media.Action.QueryParams,
      };
    }

    const location: LocationDescriptorObject = {
      pathname: `${ROUTES.PLAYER_SCREEN}/${media.Id}`,
      state: createRouteState({
        media,
        ...routeState,
      }),
    };

    location.search = UrlHelper.stringify(queryParams);
    dispatch(AudioStore.Actions.resetStore());
    dispatch(replaceRoute ? replace(location) : push(location));
  }

  static goToTrial(mediaId: Identifier, replaceRoute?: boolean): void {
    const path = UrlHelper.parametrize(`${ROUTES.PLAYER_SCREEN}/${mediaId}`, {
      streamType: MediaStreamType.Trial,
    });
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToNextEpisode(mediaId: Identifier, replaceRoute?: boolean): void {
    const path = `${ROUTES.PLAYER_SCREEN}/${mediaId}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToReadMore(
    media: IMediaModel | undefined,
    replaceRoute?: boolean,
  ): void {
    if (!media) {
      return;
    }
    const path = `${ROUTES.READ_MORE_SCREEN}/${media?.Id}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToBuyAsset(mediaId: Identifier, replaceRoute?: boolean): void {
    const path = `${ROUTES.BUY_SCREEN}/${mediaId}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToLogin(replaceRoute?: boolean): void {
    const path = `${ROUTES.LOGIN}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToLoginViaEmail(replaceRoute?: boolean): void {
    const path = `${ROUTES.LOGIN_VIA_EMAIL}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToRegister(replaceRoute?: boolean): void {
    const path = `${ROUTES.REGISTER}`;
    const state = createRouteState();
    dispatch(replaceRoute ? replace(path, state) : push(path, state));
  }

  static goToSettings(submenuKey?: string, replaceRoute?: boolean): void {
    let path = `${ROUTES.SETTINGS_SCREEN}`;

    if (submenuKey) {
      path += `/${submenuKey}`;
    }

    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToHome(submenuKey?: string, replaceRoute?: boolean): void {
    const path = `${ROUTES.HOME}/${submenuKey}`;
    dispatch(
      replaceRoute
        ? replace(path, createRouteState())
        : push(path, createRouteState()),
    );
  }

  static goToPlaylist(
    media: IMediaModel,
    cellType: CellType,
    replaceRoute?: boolean,
  ): void {
    if (!media || !media.Action) {
      return;
    }

    const basePath = `${ROUTES.PLAYLIST_SCREEN}/${media.Action.ScreenId}/${cellType}`;
    const path = media.Title ? `${basePath}/${media.Title}` : basePath;

    dispatch(replaceRoute ? replace(path) : push(path));
  }

  static getScreenRouteKey(match: RouterMatch): string | undefined {
    if (match.url.length > 0) {
      const pathSegments = match.url.slice(1).split("/");

      if (pathSegments.length > 0) {
        return pathSegments[0];
      }
    }

    return undefined;
  }

  static goBack() {
    dispatch(goBack());
  }
}
