/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";

import { useIsMobile } from "../Responsive";

import { DesktopHeader } from "./components/DesktopHeader";
import { MobileHeader } from "./components/MobileHeader";

export enum AppHeaderVisibility {
  Logo = 0,
  Menu = 1,
  Header = 2,
  UserInfo = 4,
  SignIn = 8,
  Default = 7,
}

export interface IAppHeaderProps {
  visibility?: AppHeaderVisibility;
  showBackButton?: boolean;
}

export const AppHeader = (props: IAppHeaderProps) => {
  const { visibility = AppHeaderVisibility.Default, showBackButton = false } =
    props;

  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <MobileHeader visibility={visibility} showBackButton={showBackButton} />
      ) : (
        <DesktopHeader
          visibility={visibility}
          showBackButton={showBackButton}
        />
      )}
    </>
  );
};
