/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetEventType,
  AssetType,
  AudioStore,
  BooleanHelper,
  IAppState,
  IMediaModel,
  MediaHelper,
  MediaType,
  RouteHelper,
  TimeHelper,
  useDispatch,
  useIsLoggedIn,
  useIsLoginToPlayRequired,
} from "@bms/common";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LiveWatchButton, LoginRequiredModal, MediaButton } from "components";
import { GeoBlockedModal } from "components/GeoBlockedModal";
import { AudioContext } from "context";
import LockIcon from "resources/icons/lock.svg";
import CartIcon from "resources/icons/shopping-cart.svg";
import { ReactComponent as PauseIcon } from "resources/player/pause.svg";
import { ReactComponent as PlayIcon } from "resources/player/play.svg";

export interface IPlayButtonProps {
  media?: IMediaModel;
}

export const PlayButton = ({ media }: IPlayButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    playing,
    playAudio,
    pauseAudio,
    mediaPlayInfo: rememberedMedia,
  } = useContext(AudioContext);
  const isPackage = media?.MediaTypeCode === AssetType.Package;
  const isLive = media?.MediaTypeCode === AssetEventType.Live;
  const isVideoPremiere = media?.MediaTypeCode === AssetEventType.VideoPremiere;
  const isProductOwnedByUser = useSelector<IAppState, boolean>((state) =>
    MediaHelper.isUserOwnMedia(state.auth.user, media),
  );
  const isLoggedIn = useIsLoggedIn();
  const isLoginToPlayRequired = useIsLoginToPlayRequired();
  const isAvailableDateValid = useMemo(
    () =>
      TimeHelper.getDateTime(media?.StartDateTime as Date) <=
      TimeHelper.getCurrentDateTime(),
    [media?.StartDateTime],
  );

  const isAudio = useMemo(
    () =>
      [AssetType.Podcast, AssetType.Album].indexOf(
        media?.MediaTypeCode as AssetType,
      ) > -1,
    [media],
  );
  const isGeoBlocked = useMemo(() => media?.IsGeoBlocked, [media]);
  const ctaPlayFirstLogic =
    BooleanHelper.toBool(process.env.REACT_APP_CTA_PLAY_FIRST_LOGIC, false) &&
    !isPackage;

  const onBuyClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (media) {
        RouteHelper.goToBuyAsset(media?.Id);
      }
    },
    [media],
  );

  const onAudioPlay = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    media: IMediaModel,
  ) => {
    e.stopPropagation();

    if (!isAudio) {
      return RouteHelper.goToPlayer(media);
    }

    if (media?.Id !== rememberedMedia?.MediaId) {
      if (media?.MediaTypeCode === AssetType.Podcast) {
        dispatch(
          AudioStore.Actions.setAudioMedia({ media, isFromAutoPlay: true }),
        );
      }
      if (media?.MediaTypeCode === AssetType.Album) {
        if (media?.IsPlayable) {
          dispatch(AudioStore.Actions.setAudioMedia({ media }));
        } else if (media.Media && media.Media.length > 0) {
          dispatch(
            AudioStore.Actions.setAudioMedia({ media: media?.Media[0] }),
          );
        }
      }
    } else {
      playing ? pauseAudio && pauseAudio() : playAudio && playAudio();
    }
  };

  if (!media?.AvailableFrom && !media?.StartDateTime) {
    return null;
  }

  if (
    !media ||
    (isPackage && isProductOwnedByUser) ||
    media.MediaTypeCode === MediaType.Article ||
    media.MediaTypeCode === MediaType.Advertisement
  ) {
    return null;
  }

  if (isGeoBlocked) {
    return (
      <GeoBlockedModal>
        {({ openModal }) => (
          <MediaButton
            icon={<LockIcon />}
            iconElevated
            variant="primary"
            ariaLabel={t("COMMON__BUTTON_GEOBLOCKED")}
            onClick={openModal}
          >
            {t("COMMON__BUTTON_GEOBLOCKED")}
          </MediaButton>
        )}
      </GeoBlockedModal>
    );
  }

  const playButton = (
    <LoginRequiredModal>
      {({ openModal }) => (
        <LiveWatchButton
          availableFrom={media.StartDateTime}
          availableTo={media.EndDateTime}
          variant="primary"
          icon={
            playing && media.Id === rememberedMedia?.MediaId ? (
              <PauseIcon />
            ) : (
              <PlayIcon />
            )
          }
          iconElevated={true}
          ariaLabel={t(
            isAudio ? "COMMON__BUTTON_PLAY_AUDIO" : "COMMON__BUTTON_PLAY",
          )}
          onClick={
            isLoginToPlayRequired ? openModal : (e) => onAudioPlay(e, media)
          }
        >
          {t(isAudio ? "COMMON__BUTTON_PLAY_AUDIO" : "COMMON__BUTTON_PLAY")}
        </LiveWatchButton>
      )}
    </LoginRequiredModal>
  );

  if (isProductOwnedByUser) {
    if (!media?.IsPlayable) {
      return null;
    }
    return playButton;
  }

  if (
    !isAvailableDateValid &&
    isProductOwnedByUser &&
    (isLive || isVideoPremiere)
  ) {
    return playButton;
  }

  const buttonOrderIcon = ctaPlayFirstLogic ? <PlayIcon /> : <CartIcon />;
  const buttonOrderLabel = ctaPlayFirstLogic
    ? "COMMON__BUTTON_PLAY"
    : "COMMON__BUTTON_BUY_ASSET";

  return (
    <LoginRequiredModal>
      {({ openModal }) => (
        <MediaButton
          icon={buttonOrderIcon}
          iconElevated
          variant="primary"
          ariaLabel={t(buttonOrderLabel)}
          onClick={isLoggedIn ? onBuyClick : openModal}
        >
          {t(buttonOrderLabel)}
        </MediaButton>
      )}
    </LoginRequiredModal>
  );
};
