/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IAuthCodeRequestModel } from "models/Auth/IAuthCodeRequestModel";
import { ICoinsPaymentRequestModel } from "models/CryptoCoins/ICoinsPaymentRequestModel";
import { ICoinsPaymentResponseModel } from "models/CryptoCoins/ICoinsPaymentResponseModel";
import { IFootballEntityDetailsModel } from "models/Media/IFootballEntityDetailsModel";
import { IFootballEntityDetailsOptionModel } from "models/Media/IFootballEntityDetailsOptionModel";
import { IConfirmPaymentRequestModel } from "providers/PaymentProvider/models";

import {
  AssetType,
  CellType,
  MediaImageType,
  ScreenType,
  SourceType,
} from "../../../../../common/src/enums";
import { StorageHelper, TimeHelper } from "../../../helpers";
import {
  EpgDay,
  IAssetAgeRestrictionModel,
  IAssetContentModel,
  IAssetContentTranscodeRequest,
  IAssetImageModel,
  IAssetInAssetModel,
  IAssetInAssetSearchResponseModel,
  IAssetListModel,
  IAssetModel,
  IAssetPriceListModel,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  IAssetPurchasePeriodTypeModel,
  IAssetSearchFilterModel,
  IAssetsInAssetSearchFilterModel,
  IAuthRequestModel,
  IAuthResponseModel,
  IAuthVerifyLoginModel,
  ICatchupInsertModel,
  ICatchupInsertResponseModel,
  IChangePasswordModel,
  IConfigurationBrandingModel,
  IConfigurationModel,
  IConfigurationTranslationsModel,
  IConfirmAccountWithPasswordModel,
  ICryptoCoinPriceListModel,
  ICurrencyModel,
  Identifier,
  IForgotPasswordModel,
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  IInviteManyUsersModel,
  IListComponentModel,
  ILoginCodeModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaModel,
  IMediaOptionsModel,
  IMediaPaymentRequestModel,
  IMediaPaymentResponseModel,
  IMediaPlaybackProgressModel,
  IMediaPlayInfoModel,
  IMediaPlayInfoOptionsModel,
  IMediaPurchaseOfferModel,
  IMediaPurchaseOfferRequestModel,
  IMediaSearchFilterModel,
  IMediaSearchMediaInMediaFilterModel,
  IMediaSearchStateModel,
  IMediaStatisticsOptionsModel,
  IMediaVerifyVoucherRequestModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel,
  IPaymentSearchFilterModel,
  IPaymentStatusModel,
  IPaymentTypeMappingAndOptionsModel,
  IRegisterConfirmEmailModel,
  IRegisterRequestEmailModel,
  IRemoveManyUsersModel,
  IResendConfirmationByUserModel,
  IResetForgotPasswordModel,
  IResetPasswordModel,
  IScreenModel,
  IUserAssetPropertiesModel,
  IUserAssetPurchasesListModel,
  IUserAssetPurchasesSearchFilterModel,
  IUserConsentModel,
  IUserDeleteAccountRequestModel,
  IUserDeviceModel,
  IUserInAssetModel,
  IUserInAssetRoleModel,
  IUserInfoModel,
  IUserModel,
  IUserProductModel,
  IUserPurchasesAggregatedModel,
  IUserRatingCategory,
  IUserRatingModel,
  IUserRequestOptionsModel,
  IUserSettingsModel,
  IUsersInAssetListModel,
  IUsersInAssetSearchFilterModel,
  IUsersListModel,
  IUsersSearchFilterModel,
  IUserWalletModel,
  IVoucherPriceListItemModel,
  IVouchersByPaymentKeyRequestModel,
  IVouchersItemModel,
  IVouchersPaymentRequestModel,
  TMediaVerifyVoucherRequestModel,
} from "../../../models";
import { HttpFactory } from "../../../services";
import { IDataProvider } from "../IDataProvider";
import { InternalDataProvider } from "../Internal";

import { motoConfigurationMock } from "./consts";
import {
  getClubURLWithQueryParams,
  getMediaURLWithQueryParams,
  getSeasonURLWithQueryParams,
  getURLWithQueryParams,
  getVideoURLWithQueryParams,
  MottoSourceName,
} from "./helpers/listQueryParamsMapper";
import { ModelsMapperHelper } from "./helpers/modelsMapperHelper";
import { IEventResponse } from "./models/IEventModel";
import { IVideoModel } from "./models/IVideoModel";
import {
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
} from "./services/HttpInterceptors";
import { MediaService } from "./services/MediaService";

export class MotoDataProvider implements IDataProvider {
  private _internalDataProvider = new InternalDataProvider();
  private _mediaService = new MediaService();
  private _httpFactory = HttpFactory.getInstance("MottoStreaming");
  private _mapperHelper = new ModelsMapperHelper();

  initHttpFactory() {
    this._internalDataProvider.initHttpFactory();
    this._httpFactory.baseUrl = `${process.env.REACT_APP_API_URL}`;
    this._httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor,
    );
    this._httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor,
    );
  }

  initSession = () => {
    return Promise.resolve();
  };

  getDefaultBranding(): IConfigurationBrandingModel | undefined {
    return undefined;
  }

  getDefaultTranslations(): IConfigurationTranslationsModel | undefined {
    return this._internalDataProvider._defaultTranslations;
  }

  getResource(_: string) {
    return null;
  }

  async getConfiguration(): Promise<IConfigurationModel | undefined> {
    return motoConfigurationMock;
  }

  health(): Promise<string> {
    throw new Error("Method not implemented.");
  }

  getConfigurationOffline(): Promise<IConfigurationModel | undefined> {
    throw new Error("Method not implemented.");
  }

  getScreenConfiguration(
    _: ScreenType,
    __?: number,
  ): Promise<IScreenModel | undefined> {
    throw new Error("Method not implemented.");
  }

  async getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    const { event } = await this._mediaService
      .getMedia<IEventResponse>(
        getMediaURLWithQueryParams(options.MediaId.toString()),
      )
      .toPromise();

    return {
      Id: event.id,
      Title: event.title,
      Description: event.startTime,
      IsFree: true,
      IsGeoBlocked: false,
      IsPlayable: new Date(event.startTime).getTime() < Date.now(),
      Images: [
        {
          Url: event.fields.home_club.item.thumbnail_url,
          ImageTypeCode: MediaImageType.Frame,
        },
      ],
      MediaTypeCode: AssetType.FootballEvent,
      StartDateTime: new Date(event.startTime),
      AvailableFrom: new Date(event.startTime),
      CustomMetadata: {
        ExternalVideoId: event.videoIds?.[0],
      },
    };
  }

  async getSeason(
    options: IFootballEntityDetailsOptionModel,
  ): Promise<IFootballEntityDetailsModel> {
    const url = getSeasonURLWithQueryParams(options.seasonId);

    return await this._mediaService
      .getMedia<IFootballEntityDetailsModel>(url)
      .toPromise();
  }

  async getClub(): Promise<IFootballEntityDetailsModel> {
    const url = getClubURLWithQueryParams();

    return await this._mediaService
      .getMedia<IFootballEntityDetailsModel>(url)
      .toPromise();
  }

  async getMediaPlayInfo(
    options: IMediaPlayInfoOptionsModel,
  ): Promise<IMediaPlayInfoModel> {
    // Not opt at the moment, assuming cache in the future
    const videoId = (await this.getMedia({ MediaId: options.MediaId }))
      .CustomMetadata?.ExternalVideoId;

    const url = getVideoURLWithQueryParams(videoId);
    const video = await this._mediaService
      .getMediaPlayInfo<IVideoModel>(url)
      .toPromise();

    return {
      ContentUrl: video.video.playlists[0]?.url,
    };
  }

  async searchMedia(
    options: IMediaSearchFilterModel,
  ): Promise<IMediaSearchStateModel> {
    const emptyList: IMediaListModel = {
      Entities: [],
      TotalCount: 0,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };

    const configuration = await this.getConfiguration();

    const listsPromises = configuration?.Screens?.["HOME"]?.Components?.filter(
      (el) => (el as IListComponentModel).CellType !== CellType.Highlights,
    )?.map((el) => {
      const sourceName = (el as IListComponentModel)
        .SourceName as MottoSourceName;
      return this._mediaService
        .getMediaList(getURLWithQueryParams(sourceName, options.FullTextSearch))
        .toPromise()
        .then((list) => ({
          list,
          sourceName,
        }));
    });

    if (!listsPromises?.length) {
      return emptyList;
    }

    const listsResult = await Promise.all(listsPromises);

    const entities: IMediaModel[] = listsResult
      .flatMap(
        ({ list, sourceName }) =>
          this._mapperHelper.mapListToMediaList(
            list,
            sourceName as MottoSourceName,
          )?.Entities || [],
      )
      .filter((el) => el != null) as IMediaModel[];

    if (!entities.length) {
      return emptyList;
    }

    return {
      Entities: entities as IMediaModel[],
      TotalCount: entities.length,
    };
  }

  async getMediaList(
    options: IMediaListOptionsModel,
  ): Promise<IMediaListModel> {
    const emptyList: IMediaListModel = {
      Entities: [],
      TotalCount: 0,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };

    const configuration = await this.getConfiguration();

    const list = configuration?.Screens?.["HOME"]?.Components?.find(
      (el) => "SourceId" in el && el.SourceId === options.MediaListId,
    ) as IListComponentModel;

    if (!list?.SourceName) {
      return emptyList;
    }

    const mediaList = await this._mediaService
      .getMediaList(getURLWithQueryParams(list.SourceName as MottoSourceName))
      .toPromise();

    const mappedList = this._mapperHelper.mapListToMediaList(
      mediaList,
      list.SourceName as MottoSourceName,
    );

    if (!mappedList) {
      return emptyList;
    }

    return mappedList;
  }

  async signIn(_: IAuthRequestModel): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getMediaCategories(): Promise<IMediaCategoryListModel> {
    return Promise.reject("Not implemented");
  }

  async getMediaStatistics(_: IMediaStatisticsOptionsModel) {
    return Promise.reject("Not implemented");
  }

  async getEpgDays(): Promise<EpgDay[]> {
    return [];
  }

  async selectMediaPurchaseOffers(
    _: IMediaPurchaseOfferRequestModel,
  ): Promise<IMediaPurchaseOfferModel[]> {
    return Promise.reject("Not implemented");
  }

  async setProgress(_: IMediaPlaybackProgressModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    return Promise.reject("Not implemented");
  }

  isOnMyList(_: Identifier): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  addToMyList(_: Identifier): Promise<void> {
    return Promise.reject("Not implemented");
  }

  removeFromMyList(_: Identifier): Promise<void> {
    return Promise.reject("Not implemented");
  }

  getMyListIds(): Promise<Identifier[]> {
    return StorageHelper.getMyListIds();
  }

  async buyMedia(
    _: IMediaPaymentRequestModel,
  ): Promise<IMediaPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getProducts(): Promise<IUserProductModel[]> {
    return Promise.reject("Not implemented");
  }

  async searchMediaInMedia(
    _: IMediaSearchMediaInMediaFilterModel,
  ): Promise<IMediaListModel> {
    return Promise.reject("Not implemented");
  }

  async signOut(_: IUserDeviceModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async forgotPassword(_: IForgotPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async resetForgotPassword(_: IResetForgotPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async registerEmail(_: IRegisterRequestEmailModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async registerConfirmEmail(
    _: IRegisterConfirmEmailModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async registerConfirmAccount(
    _: IConfirmAccountWithPasswordModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async resendConfirmationEmailByUser(
    _: IResendConfirmationByUserModel,
  ): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async refreshToken(
    _refreshToken: string,
    _device: IUserDeviceModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async changePassword(_: IChangePasswordModel): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async resetPassword(_: IResetPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async validateConfirmationToken(_: string): Promise<void> {
    return Promise.reject("Not implemented");
  }
  // LOGIN CODE
  async linkLoginCode(_: ILoginCodeModel): Promise<ILoginCodeModel> {
    return Promise.reject("Not implemented");
  }

  async getLoginCode(): Promise<IAuthCodeRequestModel> {
    return Promise.reject("Not implemented");
  }

  async verifyLogin(_: IAuthVerifyLoginModel): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  // USER
  async getProfile(_: IUserRequestOptionsModel): Promise<IUserModel> {
    return Promise.reject("Not implemented");
  }

  async syncUser(): Promise<IUserInfoModel> {
    return Promise.reject("Not implemented");
  }

  async updateProfile(_: IUserModel): Promise<IUserModel> {
    return Promise.reject("Not implemented");
  }

  async deleteAccount(_: IUserDeleteAccountRequestModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async browseUsers(_: IUsersSearchFilterModel): Promise<IUsersListModel> {
    return Promise.reject("Not implemented");
  }

  async getUserWallet(): Promise<IUserWalletModel[]> {
    return Promise.reject("Not implemented");
  }
  async getUserSettings(): Promise<IUserSettingsModel> {
    return Promise.reject("Not implemented");
  }

  async updateUserSettings(_: IUserSettingsModel): Promise<IUserSettingsModel> {
    return Promise.reject("Not implemented");
  }

  // USER IN ASSET
  async getUserInAssetRoles(): Promise<IUserInAssetRoleModel[]> {
    return Promise.reject("Not implemented");
  }

  async inviteManyUsers(_: IInviteManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async searchUsersInAsset(
    _: IUsersInAssetSearchFilterModel,
  ): Promise<IUsersInAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async removeUserFromAsset(
    _: IUserInAssetModel & { AssetId: number },
  ): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async removeManyUsersFromAsset(_: IRemoveManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  // CONSENTS
  async selectUserConsents(): Promise<IUserConsentModel[]> {
    return Promise.reject("Not implemented");
  }

  async getUserConsent(_: string): Promise<string> {
    return Promise.reject("Not implemented");
  }

  async updateUserConsent(_: IUserConsentModel): Promise<IUserConsentModel> {
    return Promise.reject("Not implemented");
  }

  async updateUserConsents(
    _: IUserConsentModel[],
  ): Promise<IUserConsentModel[]> {
    return Promise.reject("Not implemented");
  }

  // PAYMENT
  async getPayment(_: number): Promise<IPaymentModel> {
    return Promise.reject("Not implemented");
  }

  async searchPayment(
    _: IPaymentSearchFilterModel,
  ): Promise<IPaymentListModel> {
    return Promise.reject("Not implemented");
  }

  async getPaymentOptions(): Promise<IPaymentOptionsModel> {
    return Promise.reject("Not implemented");
  }

  async getPaymentTypeMappingAndOptions(): Promise<IPaymentTypeMappingAndOptionsModel> {
    return Promise.reject("Not implemented");
  }

  async getByKey(_: string): Promise<IPaymentModel> {
    return Promise.reject("Not implemented");
  }

  async checkStatusByKey(_: string): Promise<IPaymentStatusModel> {
    return Promise.reject("Not implemented");
  }

  async confirmPayment(
    _: IConfirmPaymentRequestModel,
  ): Promise<IPaymentStatusModel> {
    return Promise.reject("Not implemented");
  }

  // SUBSCRIPTIONS
  async cancelSubscription(_: number): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  async reactivateSubscription(
    _: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  async changeSubscriptionPaymentMethod(
    _: number,
  ): Promise<IMediaPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async searchUserAssetPurchases(
    _: IUserAssetPurchasesSearchFilterModel,
  ): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  //ASSETS
  async searchAsset(_: IAssetSearchFilterModel): Promise<IAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async getCryptoCoinPriceList(): Promise<ICryptoCoinPriceListModel[]> {
    return Promise.reject("Not implemented");
  }

  async buyCryptoCoins(
    _: ICoinsPaymentRequestModel,
  ): Promise<ICoinsPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async verifyVoucher(
    _: IMediaVerifyVoucherRequestModel,
  ): Promise<TMediaVerifyVoucherRequestModel> {
    return Promise.reject("Not implemented");
  }

  async getUserPurchasesAggregated(): Promise<IUserPurchasesAggregatedModel> {
    return Promise.reject("Not implemented");
  }

  async createAsset(
    _: IInsertAssetRequestModel,
  ): Promise<IInsertAssetResponseModel> {
    return Promise.reject("Not implemented");
  }

  async requestAssetContentTranscode(
    _: IAssetContentTranscodeRequest,
  ): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async getAsset(_: number): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async updateAsset(_: IAssetModel): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async insertAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async addAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async selectAgeRestriction(): Promise<IAssetAgeRestrictionModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectCurrency(): Promise<ICurrencyModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectPurchasePeriodType(): Promise<IAssetPurchasePeriodTypeModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetInAssetCollection(
    _: IAssetInAssetModel[],
  ): Promise<IAssetInAssetModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetPriceCollection(
    _: IAssetPriceModel[],
  ): Promise<IAssetPriceModel[]> {
    return Promise.reject("Not implemented");
  }

  async getAssetPriceCollection(_: number): Promise<IAssetPriceModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetPriceCollection(
    _: IAssetPriceSearchFilterModel,
  ): Promise<IAssetPriceListModel> {
    return Promise.reject("Not implemented");
  }

  async insertCatchup(
    _: ICatchupInsertModel,
  ): Promise<ICatchupInsertResponseModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetsInAssets(
    _: IAssetsInAssetSearchFilterModel,
  ): Promise<IAssetInAssetSearchResponseModel> {
    return Promise.reject("Not implemented");
  }

  async selectRatingCategories(): Promise<IUserRatingCategory[]> {
    return Promise.resolve([]);
  }

  async insertUserRating(_: IUserRatingModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async getVoucherPriceList(): Promise<IVoucherPriceListItemModel[]> {
    return Promise.reject("Not implemented");
  }

  async getVoucherFromPriceListById(
    _: number,
  ): Promise<IVoucherPriceListItemModel> {
    return Promise.reject("Not implemented");
  }

  async buyVoucher(_: IVouchersPaymentRequestModel) {
    return Promise.reject("Not implemented");
  }

  async getVouchers(): Promise<IVouchersItemModel[]> {
    return Promise.reject("Not implemented");
  }

  async getVoucherByPaymentKey(
    _: IVouchersByPaymentKeyRequestModel,
  ): Promise<IVouchersItemModel> {
    return Promise.reject("Not implemented");
  }
}
