/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  ConfigurationHelper,
  dispatch,
  IApplicationMenuItemComponentModel,
  IApplicationMenuScreenModel,
  RouteHelper,
  ScreenType,
  useConfigurationSelector,
  useScreenConfiguration,
} from "@bms/common";
import { push } from "connected-react-router";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { GlobalSearch } from "components/GlobalSearch";

import { HamburgerMenu, Menu } from "../Menu";

import { ApplicationMenuItem } from "./ApplicationMenuItem";

import "./ApplicationMenu.scss";

export interface IApplicationMenuProps {
  isMobile?: boolean;
}

export const ApplicationMenu = ({
  isMobile = false,
}: IApplicationMenuProps) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const configuration = useConfigurationSelector();
  const screen = useScreenConfiguration<IApplicationMenuScreenModel>(
    ScreenType.ApplicationMenu,
  );

  const componentsWithoutSearch = useMemo(
    () =>
      screen?.Components
        ? screen?.Components?.filter(
            (component) =>
              component.IsVisible &&
              component.Action?.ScreenTypeCode !== ScreenType.Search,
          )
        : [],
    [screen?.Components],
  );

  const searchComponent = useMemo(
    () =>
      screen?.Components
        ? screen.Components.find(
            (component) =>
              component.IsVisible &&
              component?.Action?.ScreenTypeCode === ScreenType.Search,
          )
        : null,
    [screen?.Components],
  );

  const selectedKeys = useMemo(() => {
    const keys: string[] = [];
    const screenKey = RouteHelper.getScreenRouteKey(match);

    if (screenKey) {
      keys.push(screenKey);

      if (isNaN(Number(screenKey)) && configuration?.Screens?.CUSTOM) {
        const screen = Object.keys(configuration.Screens.CUSTOM)
          .map((k) => configuration?.Screens?.CUSTOM?.[k])
          .find((row) => row?.RouteName === screenKey);

        if (screen) {
          keys.push(`${screen.Id}`);
        }
      }
    }

    return keys;
  }, [match]);

  if (!screen?.Components) {
    return null;
  }

  if (isMobile) {
    return (
      <HamburgerMenu>
        {screen?.Components.map((component) => {
          const screenKey =
            ConfigurationHelper.getApplicationMenuItemScreenKey(component);

          const onClick = () => {
            if (component?.Action?.ActionType) {
              switch (component.Action.ActionType) {
                case ActionType.OpenScreen:
                  if (screenKey) {
                    dispatch(push(`/${screenKey}`));
                  }
                  break;
                case ActionType.OpenUrl:
                  if (component.Action.Url) {
                    window.open(component.Action.Url, "_blank");
                  }
                  break;
              }
            }
          };
          return (
            <div
              className="ApplicationMenu__MobileItems"
              onClick={onClick}
              key={screenKey}
            >
              {t(component?.TitleTranslationKey ?? "", component?.Title ?? "")}
            </div>
          );
        })}
      </HamburgerMenu>
    );
  }

  return (
    <div className="ApplicationMenu">
      <Menu
        mode="horizontal"
        selectedKeys={selectedKeys}
        triggerSubMenuAction="click"
      >
        {componentsWithoutSearch.map((component) => {
          const screenKey =
            ConfigurationHelper.getApplicationMenuItemScreenKey(component);
          return <ApplicationMenuItem key={screenKey} component={component} />;
        })}
      </Menu>
      {searchComponent && (
        <GlobalSearch
          component={searchComponent as IApplicationMenuItemComponentModel}
        />
      )}
    </div>
  );
};
