/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ActionType, ScreenType } from "../enums";
import {
  IApplicationMenuItemComponentModel,
  IConfigurationModel,
  IScreenModel,
} from "../models/Configuration";

export class ConfigurationHelper {
  static getScreenByType(
    configuration: IConfigurationModel | undefined,
    screenType: ScreenType,
  ) {
    let screen: IScreenModel = {};

    if (configuration && configuration.Screens) {
      screen = configuration.Screens[screenType];
    }

    return screen;
  }

  static getScreenRouteKey(screen: IScreenModel) {
    switch (screen.ScreenTypeCode) {
      case ScreenType.Custom:
        return `${screen.Id}`;
      default:
        return `${screen.ScreenTypeCode?.toLowerCase()}`;
    }
  }

  static getScreenByRouteKey(
    configuration: IConfigurationModel | undefined,
    routeKey: string,
  ): IScreenModel | undefined {
    let screen: IScreenModel | undefined = undefined;
    const customScreens = configuration?.Screens?.CUSTOM;

    if (configuration && configuration.Screens) {
      screen = configuration.Screens[routeKey.toUpperCase()];

      if (!screen && customScreens) {
        screen = customScreens[routeKey];
      }
    }

    if (!screen && customScreens) {
      Object.keys(customScreens).forEach((key) => {
        if (customScreens[key].RouteName === routeKey) {
          screen = customScreens[key];
        }
      });
    }

    return screen;
  }

  static getScreenById(
    configuration: IConfigurationModel | undefined,
    screenId: number,
  ): IScreenModel | undefined {
    const scrrenKey = `${screenId}`;

    return this.getScreenByRouteKey(configuration, scrrenKey);
  }

  static getApplicationMenuItemScreenKey(
    component: Pick<IApplicationMenuItemComponentModel, "Action">,
  ) {
    switch (component?.Action?.ScreenTypeCode) {
      case ScreenType.LiveDetails:
      case ScreenType.MovieDetails:
      case ScreenType.SeriesDetails:
      case ScreenType.PackageDetails:
      case ScreenType.PodcastDetails:
        let screenKey = `${component?.Action?.ScreenTypeCode?.toLowerCase().replace(
          "_",
          "-",
        )}`;

        if (component?.Action?.ScreenId) {
          screenKey += `/${component?.Action?.ScreenId}`;
        }

        return screenKey;
      default:
        return component?.Action?.ActionType === ActionType.OpenScreen
          ? component?.Action?.ScreenId
            ? `${component?.Action?.ScreenId}`
            : `${component?.Action?.ScreenTypeCode?.toLowerCase().replace(
                "_",
                "-",
              )}`
          : "";
    }
  }

  static getScreenPathByType(screenTypeCode?: ScreenType): string {
    if (!screenTypeCode) {
      return "";
    }

    return `${screenTypeCode?.toLowerCase().replace("_", "-")}`;
  }
}
