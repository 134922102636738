/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationHeaderScreenModel,
  IComponentModel,
  ScreenType,
  useScreenConfiguration,
} from "@bms/common";
import React from "react";

import { ApplicationHeaderItem } from "./ApplicationHeaderItem";

export const ApplicationHeader = () => {
  const screen = useScreenConfiguration<IApplicationHeaderScreenModel>(
    ScreenType.ApplicationHeader,
  );

  return (
    <>
      {screen?.Components?.filter(
        (component: IComponentModel) => component.IsVisible,
      ).map((component: IComponentModel) => (
        <ApplicationHeaderItem key={component.Id} component={component} />
      ))}
    </>
  );
};
