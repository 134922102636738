/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState } from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AppHeaderVisibility, ApplicationMenu } from "components";
import RightArrowIcon from "resources/icons/right-arrow.svg";

import { HeaderLogo, HeaderSignIn, HeaderUserInfo } from "..";

import "./MobileHeader.scss";

interface IMobileHeader {
  visibility?: AppHeaderVisibility;
  showBackButton?: boolean;
}

export const MobileHeader = ({
  visibility = AppHeaderVisibility.Default,
  showBackButton = false,
}: IMobileHeader) => {
  const auth = useSelector((state: IAppState) => state.auth);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <header className="MobileHeader">
      <div className="MobileHeader__BackButtonContainer">
        {showBackButton && (
          <button
            className="MobileHeader__BackButton"
            onClick={history.goBack}
            aria-label={t("COMMON__BUTTON_GO_BACK")}
            title={t("COMMON__BUTTON_GO_BACK")}
          >
            <RightArrowIcon />
          </button>
        )}
      </div>
      <div className="MobileHeader__Left">
        <HeaderLogo />
      </div>
      <div className="MobileHeader__Right">
        {!!(visibility & AppHeaderVisibility.UserInfo) && (
          <HeaderUserInfo user={auth.user} isMobile={true} />
        )}
        {!!(visibility & AppHeaderVisibility.SignIn) && <HeaderSignIn />}
        {!!(visibility & AppHeaderVisibility.Menu) && (
          <ApplicationMenu isMobile={true} />
        )}
      </div>
    </header>
  );
};
