/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AppConfig,
  ConfigurationHelper,
  IScreenModel,
  ROUTES,
  ScreenFrameType,
  ScreenType,
  useConfigurationSelector,
  useIsLoggedIn,
  useIsUserLicensed,
} from "@bms/common";
import React, { Suspense, useCallback } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { AuthorizedRoute, LicensedRoute } from "./components";

const MainScreen = React.lazy(() =>
  import(/* webpackChunkName: "main-screen" */ "screens/MainScreen").then(
    (module) => ({
      default: module.MainScreen,
    }),
  ),
);

const CustomScreen = React.lazy(() =>
  import(/* webpackChunkName: "custom-screen" */ "screens/CustomScreen").then(
    (module) => ({
      default: module.CustomScreen,
    }),
  ),
);

const CoinsPaymentScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "coins-payment-screen" */ "screens/CoinsPaymentScreen"
  ).then((module) => ({
    default: module.CoinsPaymentScreen,
  })),
);

const CoinsPaymentSuccessScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "coins-payment-success-screen" */ "screens/CoinsPaymentSuccessScreen"
  ).then((module) => ({
    default: module.CoinsPaymentSuccessScreen,
  })),
);

const VoucherPaymentScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "voucher-payment-screen" */ "screens/VoucherPaymentScreen"
  ).then((module) => ({
    default: module.VoucherPaymentScreen,
  })),
);

const VoucherPaymentStatusScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "payment-status-screen" */ "screens/VoucherPaymentStatusScreen"
  ).then((module) => ({
    default: module.VoucherPaymentStatusScreen,
  })),
);

const ConfirmAccountWithPasswordScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "confirm-account-with-password-screen" */ "screens/AuthScreen/ConfirmAccountWithPasswordScreen"
  ).then((module) => ({
    default: module.ConfirmAccountWithPasswordScreen,
  })),
);

const ConfirmEmailScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "confirm-email-screen" */ "screens/AuthScreen/ConfirmEmailScreen"
  ).then((module) => ({
    default: module.ConfirmEmailScreen,
  })),
);

const ForgotPasswordSuccessScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "forgot-password-success-screen" */ "screens/AuthScreen/ForgotPasswordSuccessScreen"
  ).then((module) => ({
    default: module.ForgotPasswordSuccessScreen,
  })),
);

const ForgotPasswordScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "forgot-password-screen" */ "screens/AuthScreen/ForgotPasswordScreen"
  ).then((module) => ({
    default: module.ForgotPasswordScreen,
  })),
);

const ResetPasswordScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "rest-password-screen" */ "screens/AuthScreen/ResetPasswordScreen"
  ).then((module) => ({
    default: module.ResetPasswordScreen,
  })),
);

const LoginScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "login-screen" */ "screens/AuthScreen/LoginScreen"
  ).then((module) => ({
    default: module.LoginScreen,
  })),
);

const RegisterScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "register-screen" */ "screens/AuthScreen/RegisterScreen"
  ).then((module) => ({
    default: module.RegisterScreen,
  })),
);

const RegisterSocialScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "register-social-screen" */ "screens/AuthScreen/RegisterSocialScreen"
  ).then((module) => ({
    default: module.RegisterSocialScreen,
  })),
);

const RegisterSuccessScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "register-success--screen" */ "screens/AuthScreen/RegisterSuccessScreen"
  ).then((module) => ({
    default: module.RegisterSuccessScreen,
  })),
);

const MediaCreator = React.lazy(() =>
  import(
    /* webpackChunkName: "media-creator-screen" */ "components/MediaCreator"
  ).then((module) => ({
    default: module.MediaCreator,
  })),
);

const ArticleDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "article-details-screen" */ "screens/ArticleDetailsScreen"
  ).then((module) => ({
    default: module.ArticleDetailsScreen,
  })),
);

const DeleteAccountScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "delete-account-screen" */ "screens/DeleteAccountScreen"
  ).then((module) => ({
    default: module.DeleteAccountScreen,
  })),
);

const DeleteAccountSuccessScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "delete-account-success-screen" */ "screens/DeleteAccountSuccessScreen"
  ).then((module) => ({
    default: module.DeleteAccountSuccessScreen,
  })),
);

const Events = React.lazy(() =>
  import(/* webpackChunkName: "events-screen" */ "screens/EventsScreen").then(
    (module) => ({
      default: module.Events,
    }),
  ),
);

const IntroScreen = React.lazy(() =>
  import(/* webpackChunkName: "intro-screen" */ "screens/IntroScreen").then(
    (module) => ({
      default: module.IntroScreen,
    }),
  ),
);

const LinkScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "link-screen" */ "screens/LinkScreen/LinkScreen"
  ).then((module) => ({
    default: module.LinkScreen,
  })),
);

const MaintenanceScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "maintenance-screen" */ "screens/MaintenanceScreen"
  ).then((module) => ({
    default: module.MaintenanceScreen,
  })),
);

const MovieDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "movie-details-screen" */ "screens/MovieDetailsScreen"
  ).then((module) => ({
    default: module.MovieDetailsScreen,
  })),
);

const PersonDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "person-details-screen" */ "screens/PersonDetailsScreen"
  ).then((module) => ({
    default: module.PersonDetailsScreen,
  })),
);

const PackageDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "package-details-screen" */ "screens/PackageDetailsScreen"
  ).then((module) => ({
    default: module.PackageDetailsScreen,
  })),
);

const PaymentChangeStatusScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "payment-change-status-screen" */ "screens/PaymentChangeStatusScreen"
  ).then((module) => ({
    default: module.PaymentChangeStatusScreen,
  })),
);

const PaymentConfirmationScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "payment-confirmation-screen" */ "screens/PaymentConfirmationScreen"
  ).then((module) => ({
    default: module.PaymentConfirmationScreen,
  })),
);

const PaymentScreen = React.lazy(() =>
  import(/* webpackChunkName: "payment-screen" */ "screens/PaymentScreen").then(
    (module) => ({
      default: module.PaymentScreen,
    }),
  ),
);

const PaymentStatusScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "payment-status-screen" */ "screens/PaymentStatusScreen"
  ).then((module) => ({
    default: module.PaymentStatusScreen,
  })),
);

const PlaylistScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "playlist-screen" */ "screens/PlaylistScreen"
  ).then((module) => ({
    default: module.PlaylistScreen,
  })),
);

const PodcastDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "podcast-details-screen" */ "screens/PodcastDetailsScreen"
  ).then((module) => ({
    default: module.PodcastDetailsScreen,
  })),
);

const PrivacyPolicyScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "privacy-policy-screen" */ "screens/PrivacyPolicyScreen"
  ).then((module) => ({
    default: module.PrivacyPolicyScreen,
  })),
);

const SearchScreen = React.lazy(() =>
  import(/* webpackChunkName: "search-screen" */ "screens/SearchScreen").then(
    (module) => ({
      default: module.SearchScreen,
    }),
  ),
);

const SeriesDetailsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "series-details-screen" */ "screens/SeriesDetailsScreen"
  ).then((module) => ({
    default: module.SeriesDetailsScreen,
  })),
);

const SettingsScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "settings-screen" */ "screens/SettingsScreen"
  ).then((module) => ({
    default: module.SettingsScreen,
  })),
);

const Watch = React.lazy(() =>
  import(/* webpackChunkName: "watch-screen" */ "screens/Watch").then(
    (module) => ({
      default: module.Watch,
    }),
  ),
);

export const AppRouter = () => {
  const configuration = useConfigurationSelector();
  const isUserLicensed = useIsUserLicensed();
  const isUserLoggedIn = useIsLoggedIn();
  const location = useLocation();

  const createCustomRoute = useCallback(
    (
      screen: IScreenModel,
      variant: "normal" | "mapped-route" = "normal",
    ): React.ReactElement => {
      const routeKey = ConfigurationHelper.getScreenRouteKey(screen);
      const component =
        screen.ScreenFrameTypeCode === ScreenFrameType.Custom
          ? CustomScreen
          : MainScreen;

      if (variant === "normal") {
        return (
          <LicensedRoute
            exact
            key={screen.Id}
            path={`/${screen.RouteName ?? routeKey}`}
            component={component}
          />
        );
      }

      return (
        <Route
          exact
          key={screen.Id}
          path={`/${routeKey}`}
          component={component}
        >
          {!!screen.RouteName && (
            <Redirect
              to={{
                pathname: screen.RouteName,
              }}
            />
          )}
        </Route>
      );
    },
    [],
  );

  const createCustomRoutes = useCallback(() => {
    if (!configuration) {
      return null;
    }

    const routes: React.ReactElement[] = [];

    if (configuration && configuration.Screens) {
      for (const screenKey in configuration.Screens) {
        switch (screenKey) {
          case ScreenType.Custom:
            const customScreens = configuration.Screens.CUSTOM;

            for (const customScreenKey in customScreens) {
              routes.push(
                createCustomRoute(customScreens[customScreenKey], "normal"),
              );
              routes.push(
                createCustomRoute(
                  customScreens[customScreenKey],
                  "mapped-route",
                ),
              );
            }

            break;
          case ScreenType.Intro:
          case ScreenType.Search:
          case ScreenType.ApplicationHeader:
          case ScreenType.ApplicationFooter:
          case ScreenType.ApplicationMenu:
          case ScreenType.Register:
          case ScreenType.RegisterSuccess:
            // Skip creating route for predefined screens;
            continue;
          default:
            routes.push(createCustomRoute(configuration.Screens[screenKey]));

            break;
        }
      }
    }

    return routes;
  }, [configuration, createCustomRoute]);

  const renderRedirect = () => {
    if (process.env.REACT_APP_START_ROUTE) {
      if (location.pathname === ROUTES.BASE && isUserLicensed) {
        return <Redirect to={process.env.REACT_APP_START_ROUTE} />;
      }
    }

    if (isUserLoggedIn) {
      return <Redirect to={ROUTES.HOME} />;
    }

    return null;
  };

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path="/" component={IntroScreen}>
          {renderRedirect()}
        </Route>
        {createCustomRoutes()}
        <LicensedRoute
          exact
          path={`${ROUTES.MOVIE_DETAILS_SCREEN}/:id`}
          component={MovieDetailsScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PERSON_DETAILS_SCREEN}/:id`}
          component={PersonDetailsScreen}
        />
        <LicensedRoute
          exact
          path={[
            `${ROUTES.PODCAST_DETAILS_SCREEN}/:id`,
            `${ROUTES.ALBUM_DETAILS_SCREEN}/:id`,
          ]}
          component={PodcastDetailsScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.SERIES_DETAILS_SCREEN}/:id`}
          component={SeriesDetailsScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PACKAGE_DETAILS_SCREEN}/:id`}
          component={PackageDetailsScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.ARTICLE_SCREEN}/:id`}
          component={ArticleDetailsScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PLAYER_SCREEN}/:id`}
          component={Watch}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PRIVACY_POLICY_SCREEN}/:id`}
          component={PrivacyPolicyScreen}
        />
        <LicensedRoute exact path={`${ROUTES.EVENTS}`} component={Events} />
        <LicensedRoute
          exact
          path={`${ROUTES.MEDIA_CREATOR}/:id`}
          component={MediaCreator}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PLAYLIST_SCREEN}/:id/:cellType/:title?`}
          component={PlaylistScreen}
        />
        <LicensedRoute
          exact
          path={ROUTES.SEARCH_SCREEN}
          component={SearchScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.BUY_SCREEN}/:id`}
          component={PaymentScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.BUY_CONFIRMATION_SCREEN}`}
          component={PaymentConfirmationScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PAYMENT_STATUS_SCREEN}/:id`}
          component={PaymentStatusScreen}
        />
        <LicensedRoute
          exact
          path={`${ROUTES.PAYMENT_CHANGE_STATUS_SCREEN}/:id`}
          component={PaymentChangeStatusScreen}
        />
        <AuthorizedRoute
          exact
          path={ROUTES.SETTINGS_SCREEN}
          component={SettingsScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.SETTINGS_SCREEN}/:submenuKey`}
          component={SettingsScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.COINS_PAYMENT}`}
          component={CoinsPaymentScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.COINS_PAYMENT_SUCCESS}`}
          component={CoinsPaymentSuccessScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.VOUCHER_PAYMENT}/:id`}
          component={VoucherPaymentScreen}
        />
        <AuthorizedRoute
          exact
          path={`${ROUTES.VOUCHER_PAYMENT_STATUS}/:id`}
          component={VoucherPaymentStatusScreen}
        />
        {!AppConfig.IsLoginDisabled && (
          <Route exact path={`${ROUTES.LOGIN}`} component={LoginScreen} />
        )}
        <Route exact path={ROUTES.REGISTER} component={RegisterScreen} />
        <Route
          exact
          path={ROUTES.REGISTER_SOCIAL}
          component={RegisterSocialScreen}
        />
        <Route
          exact
          path={ROUTES.FORGOT_PASSWORD}
          component={ForgotPasswordScreen}
        />
        <Route
          exact
          path={ROUTES.REGISTER_SUCCESS}
          component={RegisterSuccessScreen}
        />
        <Route
          exact
          path={ROUTES.FORGOT_PASSWORD_SUCCESS}
          component={ForgotPasswordSuccessScreen}
        />
        <Route
          exact
          path={ROUTES.RESET_PASSWORD}
          component={ResetPasswordScreen}
        />
        <Route
          exact
          path={ROUTES.REGISTER_CONFIRM}
          component={ConfirmEmailScreen}
        />
        <Route
          exact
          path={ROUTES.REGISTER_CONFIRM_ACCOUNT_WITH_PASSWORD}
          component={ConfirmAccountWithPasswordScreen}
        />
        <Route exact path={ROUTES.LINK_SCREEN} component={LinkScreen} />
        <Route
          exact
          path={`${ROUTES.DELETE_ACCOUNT}`}
          component={DeleteAccountScreen}
        />
        <Route
          exact
          path={`${ROUTES.DELETE_ACCOUNT_SUCCESS}`}
          component={DeleteAccountSuccessScreen}
        />
        <Route
          exact
          path={ROUTES.MAINTENANCE_SCREEN}
          component={MaintenanceScreen}
        />
      </Switch>
    </Suspense>
  );
};
