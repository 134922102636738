/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel } from "@bms/common";
import { createContext } from "react";

const defaultValue = {
  media: undefined,
};

export interface IMediaContext {
  media?: IMediaModel;
}

export const MediaContext = createContext<IMediaContext>(defaultValue);
