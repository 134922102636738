/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getMessaging,
  isSupported,
  Messaging,
  onMessage,
} from "firebase/messaging";

export type TFirebaseInterface = TFirebaseResolved | null;

type TFirebaseResolved = {
  app: FirebaseApp;
  messaging: Messaging;
};

const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const firebaseDatabaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const firebaseMessagingSenderId =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const isFirebaseAvailable =
  firebaseApiKey &&
  firebaseAuthDomain &&
  firebaseProjectId &&
  firebaseStorageBucket &&
  firebaseMessagingSenderId &&
  firebaseAppId;

export let firebaseInterfaceInstance: TFirebaseInterface = null;

export const firebasePromise = (
  isFirebaseAvailable ? Promise.resolve() : Promise.reject()
)
  .then(isSupported)
  .then((isSupportedResolvedValue) => {
    if (!isSupportedResolvedValue) {
      throw new Error("Firebase is not supported");
    }
  })
  .then<TFirebaseResolved>(() => {
    const firebaseConfig = {
      apiKey: firebaseApiKey,
      authDomain: firebaseAuthDomain,
      ...(firebaseDatabaseUrl && { databaseURL: firebaseDatabaseUrl }),
      projectId: firebaseProjectId,
      storageBucket: firebaseStorageBucket,
      messagingSenderId: firebaseMessagingSenderId,
      appId: firebaseAppId,
      measurementId: firebaseMeasurementId,
    };

    if (__DEV__) {
      console.log(
        "%cFirebase - config: ",
        "color: blue;font-weight: bold;",
        firebaseConfig,
      );
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      if (__DEV__) {
        console.log(
          "%cFirebase - received message:",
          "color: blue;font-weight: bold;",
          payload,
        );
      }

      const { title, body, image, url } = payload.data ?? {};

      if (title && body) {
        const notification = new Notification(title, {
          body: body,
          icon: image,
        });

        notification.onclick = () => {
          if (url) {
            window.open(url, "_blank");
          }
        };
      }
    });
    return { app, messaging };
  })
  .then((resolvedValue) => (firebaseInterfaceInstance = resolvedValue))
  .catch((_err) => null);
