/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import {
  IVouchersByPaymentKeyRequestModel,
  IVouchersItemModel,
  IVouchersPaymentRequestModel,
  IVouchersPaymentResponseModel,
} from "models";

import { HTTP_METHOD } from "../../../../constants";
import { AxiosSubscriber, PromisifiableBase } from "../../../../services";

export class VouchersService extends PromisifiableBase {
  get url(): string {
    return "/Vouchers";
  }

  public select = (): Observable<IVouchersItemModel[]> =>
    new Observable(
      (observer: Observer<IVouchersItemModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        }),
    );

  public buy = (
    data: IVouchersPaymentRequestModel,
  ): Observable<IVouchersPaymentResponseModel> =>
    new Observable(
      (observer: Observer<IVouchersPaymentResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Buy`,
        }),
    );

  public getByPaymentKey = ({
    PaymentKey,
  }: IVouchersByPaymentKeyRequestModel): Observable<IVouchersItemModel> =>
    new Observable(
      (observer: Observer<IVouchersItemModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              paymentKey: PaymentKey,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetByPaymentKey`,
        }),
    );
}
