/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { IVoucherPriceListItemModel } from "models";

import { HTTP_METHOD } from "../../../../constants";
import { AxiosSubscriber, PromisifiableBase } from "../../../../services";

export class VoucherPriceListService extends PromisifiableBase {
  get url(): string {
    return "/VoucherPriceList";
  }

  public selectByCurrency = (
    currencyCode?: string,
  ): Observable<IVoucherPriceListItemModel[]> =>
    new Observable(
      (observer: Observer<IVoucherPriceListItemModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              currencyCode,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/SelectByCurrency`,
        }),
    );

  public get = (id: number): Observable<IVoucherPriceListItemModel> =>
    new Observable(
      (observer: Observer<IVoucherPriceListItemModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        }),
    );
}
