/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useRouteMatch } from "react-router-dom";

import { ScreenType } from "../../enums/Configuration";
import { ConfigurationHelper, RouteHelper } from "../../helpers";
import { IScreenModel } from "../../models";
import { IAppState, useSelector } from "../../store";

export const useScreenConfiguration = <T extends IScreenModel = IScreenModel>(
  screenType: ScreenType,
): T => {
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration,
  );

  let screen: T = {} as T;

  if (
    configuration &&
    configuration.Screens &&
    configuration.Screens[screenType]
  ) {
    screen = configuration.Screens[screenType] as T;
  }

  return screen;
};

export const useScreenConfigurationByRoute = <
  T extends IScreenModel = IScreenModel,
>(): T => {
  const match = useRouteMatch();
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration,
  );
  const screenKey = RouteHelper.getScreenRouteKey(match);

  let screen: T = {} as T;

  if (screenKey) {
    screen = ConfigurationHelper.getScreenByRouteKey(
      configuration,
      screenKey,
    ) as T;
  }

  return screen;
};
