/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IMediaListOptionsModel,
  IMediaOptionsModel,
  IMediaPaymentRequestModel,
  IMediaPlayInfoOptionsModel,
  IMediaSearchMediaInMediaFilterModel,
} from "../../../../models";

export class ModelsMapperHelper {
  static toMediaOptionsModel(data: IMediaOptionsModel): IMediaOptionsModel {
    return {
      ...data,
      MediaId: +data.MediaId,
    };
  }

  static toMediaPlayInfoOptionsModel(
    data: IMediaPlayInfoOptionsModel,
  ): IMediaPlayInfoOptionsModel {
    return {
      ...data,
      MediaId: +data.MediaId,
    };
  }

  static toMediaListOptionsModel(
    data: IMediaListOptionsModel,
  ): IMediaListOptionsModel {
    return {
      ...data,
      MediaListId: +data.MediaListId,
      MediaId: data.MediaId ? +data.MediaId : undefined,
    };
  }

  static toMediaPaymentRequestModel(
    data: IMediaPaymentRequestModel,
  ): IMediaPaymentRequestModel {
    return {
      ...data,
      MediaId: +data.MediaId,
    };
  }

  static toMediaSearchMediaInMediaFilterModel(
    data: IMediaSearchMediaInMediaFilterModel,
  ): IMediaSearchMediaInMediaFilterModel {
    return {
      ...data,
      MediaParentId: data.MediaParentId ? +data.MediaParentId : undefined,
    };
  }
}
