/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  AudioStore,
  BooleanHelper,
  IAppState,
  IMediaModel,
  MediaHelper,
  MediaType,
  ROUTES,
  TimeHelper,
  useDispatch,
  useIsLoggedIn,
  useIsLoginToPlayRequired,
} from "@bms/common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LoginRequiredModal } from "../../components";
import PlayFilledIcon from "../../resources/icons/play-filled.svg";
import CartIcon from "../../resources/icons/shopping-cart.svg";

export interface IPlayInlineButtonProps {
  media?: IMediaModel;
}

export const PlayInlineButton: React.FC<IPlayInlineButtonProps> = (props) => {
  const { media } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAudio = useMemo(
    () =>
      [AssetType.Album, AssetType.Podcast].indexOf(
        media?.MediaTypeCode as AssetType,
      ) > -1,
    [media],
  );
  const isPackage = media?.MediaTypeCode === AssetType.Package;
  const isArticle = media?.MediaTypeCode === MediaType.Article;
  const isAlbum = media?.MediaTypeCode === MediaType.Album;
  const isProductOwnedByUser = useSelector<IAppState, boolean>((state) =>
    MediaHelper.isUserOwnMedia(state.auth.user, media),
  );
  const ctaPlayFirstLogic =
    BooleanHelper.toBool(process.env.REACT_APP_CTA_PLAY_FIRST_LOGIC, false) &&
    !isPackage;
  const isLoginToPlayRequired = useIsLoginToPlayRequired();
  const isLoggedIn = useIsLoggedIn();
  const isAvailable = useMemo(() => {
    if (!media) {
      return false;
    }

    const { StartDateTime, EndDateTime } = media;

    return (
      (!StartDateTime || TimeHelper.isBeforeCurrent(StartDateTime)) &&
      (!EndDateTime || TimeHelper.isAfterCurrent(EndDateTime))
    );
  }, [media]);

  if (
    !media ||
    (isPackage && isProductOwnedByUser) ||
    isArticle ||
    isAlbum ||
    !isAvailable
  ) {
    return null;
  }

  if (isProductOwnedByUser) {
    if (isLoginToPlayRequired) {
      return (
        <LoginRequiredModal>
          <div className="MediaInfo__action MediaInfo__action--watch">
            <i className="MediaInfo__action-icon">
              <PlayFilledIcon />
            </i>
            <span>
              {t(
                isAudio ? "COMMON__BUTTON_PLAY_AUDIO" : "COMMON__BUTTON_WATCH",
              )}
            </span>
          </div>
        </LoginRequiredModal>
      );
    }

    return (
      <Link
        to={
          isAudio
            ? `${
                media?.MediaTypeCode === AssetType.Podcast
                  ? ROUTES.PODCAST_DETAILS_SCREEN
                  : ROUTES.ALBUM_DETAILS_SCREEN
              }/${media.Id}`
            : `${ROUTES.PLAYER_SCREEN}/${media.Id}`
        }
        onClick={
          isAudio
            ? () => dispatch(AudioStore.Actions.setAudioMedia({ media }))
            : undefined
        }
        className="MediaInfo__action MediaInfo__action--watch"
      >
        <i
          className="MediaInfo__action-icon"
          aria-label={
            isAudio ? t("COMMON__BUTTON_PLAY_AUDIO") : t("COMMON__BUTTON_WATCH")
          }
        >
          <PlayFilledIcon />
        </i>
        <span>
          {t(isAudio ? "COMMON__BUTTON_PLAY_AUDIO" : "COMMON__BUTTON_WATCH")}
        </span>
      </Link>
    );
  }

  const buttonOrderIcon = ctaPlayFirstLogic ? <PlayFilledIcon /> : <CartIcon />;
  const buttonOrderLabel = ctaPlayFirstLogic
    ? "COMMON__BUTTON_WATCH"
    : "COMMON__BUTTON_BUY_ASSET";

  if (isLoggedIn) {
    return (
      <Link
        to={`${ROUTES.BUY_SCREEN}/${media.Id}`}
        className="MediaInfo__action MediaInfo__action--watch"
      >
        <i className="MediaInfo__action-icon">{buttonOrderIcon}</i>
        <span>{t(buttonOrderLabel)}</span>
      </Link>
    );
  }

  return (
    <LoginRequiredModal>
      <div className="MediaInfo__action MediaInfo__action--watch">
        <i className="MediaInfo__action-icon">{buttonOrderIcon}</i>
        <span>{t(buttonOrderLabel)}</span>
      </div>
    </LoginRequiredModal>
  );
};
