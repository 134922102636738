/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { BehaviorSubject } from "rxjs";

import { ANONYMOUS_ID } from "../constants";
import {
  Identifier,
  IUserAssetPropertiesModel,
  IUserInfoModel,
} from "../models";
import { StorageKey, StorageManager } from "../services";

export class StorageHelperBase {
  public static userAssetProperties$ = new BehaviorSubject<
    IUserAssetPropertiesModel[]
  >([]);

  static getUser(): Promise<IUserInfoModel | null> {
    return StorageManager.getValue(StorageKey.userId).then((userId) => {
      return StorageManager.getValue(StorageKey.users).then((users) => {
        if (users && userId) {
          return users[userId];
        }
        return null;
      });
    });
  }

  static getBackendVersion() {
    return StorageManager.getValue(StorageKey.backendVersion);
  }

  static async getBrandingLogo(): Promise<string | undefined> {
    const configuration = await StorageManager.getValue(
      StorageKey.configuration,
    );

    if (configuration) {
      return configuration.Branding?.AppLogoUrl;
    }
    return undefined;
  }

  static async getBrandingLogoExtended(): Promise<string | undefined> {
    const configuration = await StorageManager.getValue(
      StorageKey.configuration,
    );

    if (configuration) {
      return configuration.Branding?.AppLogoExtendedUrl;
    }
    return undefined;
  }

  static setUser(user: IUserInfoModel | undefined): Promise<void> {
    if (!user) {
      return Promise.resolve();
    }
    return StorageManager.getValue(StorageKey.users)
      .then((users) => {
        if (!users) {
          users = {};
        }
        if (user.Id) {
          // keep assetProperties
          const assetsProperties = users[user.Id]?.assetsProperties;
          users[user.Id] = { ...user, assetsProperties };
        }
        return users;
      })
      .then((users) => StorageManager.setValue(StorageKey.users, users))
      .then(() => StorageManager.setValue(StorageKey.userId, user.Id));
  }

  static async deleteUser(): Promise<void> {
    let users = (await StorageManager.getValue(StorageKey.users)) || {};
    // Get anonymous user data
    const anonymousUser = users[ANONYMOUS_ID];
    // Resert all users data
    users = {};

    if (anonymousUser) {
      users[ANONYMOUS_ID] = anonymousUser;
    }

    await StorageManager.setValue(StorageKey.users, users);
    // Set anonymous user as current user
    return StorageManager.setValue(StorageKey.userId, ANONYMOUS_ID);
  }

  static userIdAndStoragePromise() {
    return Promise.all([
      StorageManager.getValue(StorageKey.userId),
      StorageManager.getValue(StorageKey.users),
    ]);
  }

  /**
   * Replace only one asset props.
   * @param userAssetProperties All asset properties with the same `AssetId` will be replaced with this props. Other props will be intact
   */
  static updateUserAssetsProperties(
    userAssetProperties: IUserAssetPropertiesModel,
  ): Promise<void> {
    return this.userIdAndStoragePromise().then(([userId, users]) => {
      if (userId && users) {
        const oldAssetProperties =
          users[userId].assetsProperties?.filter(
            (item: any) => item.AssetId != userAssetProperties.AssetId,
          ) ?? [];
        const newAssetProperties = [userAssetProperties, ...oldAssetProperties];
        return this.setUserAssetsProperties(newAssetProperties);
      }
    });
  }

  /**
   * It clear all previous properties, and save new.
   * @param userAssetsProperties Properties array which will be stored in storage
   */
  static setUserAssetsProperties(
    userAssetsProperties: IUserAssetPropertiesModel[],
  ): Promise<void> {
    return this.userIdAndStoragePromise().then(([userId, users]) => {
      if (userId && users) {
        users[userId].assetsProperties = userAssetsProperties;
        this.userAssetProperties$.next(userAssetsProperties);
        return StorageManager.setValue(StorageKey.users, users);
      }
    });
  }

  static getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    return this.userIdAndStoragePromise().then(([userId, users]) => {
      if (userId && users) {
        return users[userId].assetsProperties ?? [];
      } else {
        return [];
      }
    });
  }

  static isOnMyList(mediaId: Identifier): Promise<boolean> {
    return this.getUserAssetsProperties().then((assetsProperties) => {
      const assetProps = assetsProperties.find(
        (item) => item.AssetId == mediaId,
      );
      return assetProps?.IsFavorite == true;
    });
  }

  static addToMyList(mediaId: Identifier): Promise<void> {
    return this.setFavourite(mediaId, true);
  }

  static removeFromMyList(mediaId: Identifier): Promise<void> {
    return this.setFavourite(mediaId, false);
  }

  private static setFavourite(
    mediaId: Identifier,
    isFavourite: boolean,
  ): Promise<void> {
    return this.getUserAssetsProperties()
      .then((assetsProperties) => {
        const assetProps = assetsProperties.find(
          (item) => item.AssetId == mediaId,
        );
        return <IUserAssetPropertiesModel>{
          ...assetProps,
          AssetId: mediaId,
          IsFavorite: isFavourite,
        };
      })
      .then((assetProperties) =>
        this.updateUserAssetsProperties(assetProperties),
      );
  }

  static async getMyListIds(): Promise<Identifier[]> {
    const assetProperties = await this.getUserAssetsProperties();
    return assetProperties
      .filter((asset) => Boolean(asset.IsFavorite))
      .map((asset) => asset.AssetId);
  }

  static setFirebaseMessagingToken(token: string): Promise<void> {
    return StorageManager.setValue(StorageKey.firebaseMsgToken, token);
  }
}
