/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useEffect, useState } from "react";

import {
  firebaseInterfaceInstance,
  firebasePromise,
  type TFirebaseInterface,
} from "config";

export const useFirebaseInterface = () => {
  const [firebaseInterface, setFirebaseInterface] =
    useState<TFirebaseInterface>(firebaseInterfaceInstance);

  useEffect(() => {
    if (firebaseInterfaceInstance) {
      return;
    }
    firebasePromise.then((firebaseResolved) => {
      if (!firebaseResolved) {
        return;
      }
      setFirebaseInterface(firebaseResolved);
    });
  }, []);

  return firebaseInterface;
};
