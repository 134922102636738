/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  ComponentType,
  PlatformType,
  ScreenType,
} from "../../enums";
import { PlatformHelper } from "../../helpers/platformHelper";
import { ISettingsMenuScreenModel } from "../../models";
import { IAppState, useSelector } from "../../store";

import { useScreenConfiguration } from "./useScreenConfiguration";

export const useSettingsMenuConfiguration = (): ISettingsMenuScreenModel => {
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration,
  );
  const isPaymentEnabled = configuration?.System?.Payment?.Enabled ?? true;
  const isCryptoCoinEnabled =
    configuration?.System?.CryptoCoin?.Enabled ?? false;
  const isVouchersEnabled = configuration?.System?.Voucher?.Enabled ?? false;

  let settingsMenu = useScreenConfiguration<ISettingsMenuScreenModel>(
    ScreenType.SettingsMenu,
  );

  if (settingsMenu && settingsMenu?.Components) {
    const onlyEnabledComponents = settingsMenu.Components.filter(
      (component) => {
        switch (component.Action?.ScreenTypeCode) {
          case ScreenType.UserOrders:
            return isPaymentEnabled;
          case ScreenType.Vouchers:
            return isVouchersEnabled;
          case ScreenType.Wallet:
            return isCryptoCoinEnabled;
          default:
            return true;
        }
      },
    );
    return { ...settingsMenu, Components: onlyEnabledComponents };
  }

  settingsMenu = {
    ScreenTypeCode: ScreenType.SettingsMenu,
    Components: [
      {
        Id: 1,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__PROFILE",
        IconResourceKey: "ICON_PROFILE",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Profile,
        },
      },
      {
        Id: 2,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__MY_ORDERS",
        IconResourceKey: "ICON_USER_ORDERS",
        IsVisible: isPaymentEnabled,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.UserOrders,
        },
      },
      {
        Id: 4,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__TERMS_AND_CONDITIONS",
        IconResourceKey: "ICON_CONSENTS",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Consents,
        },
      },
      {
        Id: 5,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__ABOUT",
        IconResourceKey: "ICON_INFO",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.About,
        },
      },
    ],
  };

  switch (PlatformHelper.Platform) {
    case PlatformType.AndroidPhone:
    case PlatformType.AndroidTablet:
    case PlatformType.iOSPhone:
    case PlatformType.iPad:
      settingsMenu.Components?.push({
        Id: 5,
        ComponentTypeCode: ComponentType.SettingsMenuItem,
        TitleTranslationKey: "COMMON__HELP",
        IconResourceKey: "ICON_HELP",
        IsVisible: true,
        Action: {
          ActionType: ActionType.OpenScreen,
          ScreenTypeCode: ScreenType.Help,
        },
      });
      break;
    case PlatformType.Web:
      settingsMenu.Components?.splice(
        2,
        0,
        {
          Id: 3,
          ComponentTypeCode: ComponentType.SettingsMenuItem,
          TitleTranslationKey: "COMMON__WALLET",
          Title: "Crypto coins",
          IconResourceKey: "ICON_WALLET",
          IsVisible: isCryptoCoinEnabled,
          Action: {
            ActionType: ActionType.OpenScreen,
            ScreenTypeCode: ScreenType.Wallet,
          },
        },
        {
          Id: 8,
          ComponentTypeCode: ComponentType.SettingsMenuItem,
          TitleTranslationKey: "COMMON__VOUCHERS",
          Title: "Vouchers",
          IconResourceKey: "ICON_WALLET",
          IsVisible: isVouchersEnabled,
          Action: {
            ActionType: ActionType.OpenScreen,
            ScreenTypeCode: ScreenType.Vouchers,
          },
        },
      );
      break;
  }

  settingsMenu.Components?.push({
    Id: 7,
    ComponentTypeCode: ComponentType.SettingsMenuItem,
    TitleTranslationKey: "COMMON__LANGUAGE",
    IconResourceKey: "ICON_PREFERENCES",
    IsVisible: true,
    Action: {
      ActionType: ActionType.OpenScreen,
      ScreenTypeCode: ScreenType.Preferences,
    },
  });

  return settingsMenu;
};
