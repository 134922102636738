/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { KeyboardKeyCode, PlatformType } from "../enums";

declare global {
  interface Window {
    PalmSystem: any;

    tizen: any;

    webOS: any;

    MSStream?: any;

    registration?: any;

    __WB_MANIFEST?: any;
  }
}

let _platform: PlatformType | undefined;

// Set platform for development
if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_PLATFORM_CODE
) {
  switch (process.env.REACT_APP_PLATFORM_CODE) {
    case "ANY":
      _platform = PlatformType.Any;
      break;
    case "ANDROID_PHONE":
      _platform = PlatformType.AndroidPhone;
      break;
    case "ANDROID_TABLET":
      _platform = PlatformType.AndroidTablet;
      break;
    case "ANDROID_TV":
      _platform = PlatformType.AndroidTV;
      break;
    case "APPLE_TV":
      _platform = PlatformType.AppleTV;
      break;
    case "IOS_PHONE":
      _platform = PlatformType.iOSPhone;
      break;
    case "IPAD":
      _platform = PlatformType.iPad;
      break;
    case "TIZEN":
      _platform = PlatformType.Tizen;
      break;
    case "WEB":
      _platform = PlatformType.Web;
      break;
    case "WEB_OS":
      _platform = PlatformType.WebOS;
      break;
    case "ROKU":
      _platform = PlatformType.Roku;
      break;
    case "VIZIO":
      _platform = PlatformType.Vizio;
      break;
    default:
      _platform = undefined;
      break;
  }

  console.log("PLATFORM SET TO:", _platform);
}

export class PlatformHelper {
  static isTV = () => {
    return (
      PlatformHelper.isTizen() ||
      PlatformHelper.isWebos() ||
      PlatformHelper.isVizio()
    );
  };

  static isTizen = () => {
    return !!(window && window.tizen);
  };

  static isWebos = () => {
    return (
      window.hasOwnProperty("webOSSystem") || window.hasOwnProperty("webos")
    );
  };

  static isVizio = () => {
    return !!window?.navigator?.userAgent?.includes("VIZIO");
  };

  static isLowSpecDevice = () => {
    return !!(window && window.tizen);
  };

  static closeApp = () => {
    if (PlatformHelper.isTizen()) {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      window.close();
    }
  };

  static registerKeyboardKeys(): void {
    const platform = this.Platform;

    switch (platform) {
      case PlatformType.Tizen:
        if (typeof window.tizen !== "undefined") {
          window.tizen.tvinputdevice.registerKey("MediaRewind");
          window.tizen.tvinputdevice.registerKey("MediaFastForward");
          window.tizen.tvinputdevice.registerKey("MediaPlay");
          window.tizen.tvinputdevice.registerKey("MediaPause");
          window.tizen.tvinputdevice.registerKey("MediaStop");
        }
        break;
      default:
        break;
    }
  }

  static getKeyboardKeyCode(key: number): KeyboardKeyCode {
    const platform = this.Platform;

    switch (platform) {
      case PlatformType.Tizen:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 8:
          case 10009:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          case 10182:
            return KeyboardKeyCode.Exit;
          default:
            return KeyboardKeyCode.Undefined;
        }
      case PlatformType.WebOS:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 8:
          case 461:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          case 10182:
            return KeyboardKeyCode.Exit;
          default:
            return KeyboardKeyCode.Undefined;
        }
      default:
        switch (key) {
          case 8:
            return KeyboardKeyCode.Back;
          case 13:
            return KeyboardKeyCode.Enter;
          case 27:
            return KeyboardKeyCode.ESC;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          default:
            return KeyboardKeyCode.Undefined;
        }
    }
  }

  static get Platform(): PlatformType {
    if (_platform) {
      return _platform;
    }

    if (PlatformHelper.isTizen()) {
      _platform = PlatformType.Tizen;
      return _platform;
    } else if (PlatformHelper.isWebos()) {
      _platform = PlatformType.WebOS;
      return _platform;
    } else if (PlatformHelper.isVizio()) {
      _platform = PlatformType.Vizio;
      return _platform;
    }

    _platform = PlatformType.Web;

    return _platform;
  }

  static getPlatformVersion = (): string => {
    let version = "1.0";

    switch (PlatformHelper.Platform) {
      case PlatformType.Tizen:
        if (typeof window.tizen !== "undefined") {
          try {
            const platform = window.tizen.systeminfo.getCapability(
              "http://tizen.org/feature/platform.version",
            );
            version = `${platform}`;
          } catch (error) {
            console.error(
              "Get Platform Version Error: ",
              JSON.stringify(error),
            );
          }
        }
        break;
    }

    return version;
  };

  static getPlatformVersionNumber = (): number => {
    const version = PlatformHelper.getPlatformVersion();

    const versionNumber = parseFloat(version);

    return versionNumber;
  };
}
