/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationMenuItemComponentModel,
  IAppState,
  IMediaSearchSuggestionModel,
  MediaSuggestionType,
  ROUTES,
  UrlHelper,
  useSelector,
} from "@bms/common";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { Input } from "components";
import { useOutsideClick } from "hooks/useOutsideClick";
import { SearchScreenQueryParams } from "screens/SearchScreen/SearchScreen";

import "./GlobalSearch.scss";

export interface IGlobalSearchProps {
  component?: IApplicationMenuItemComponentModel;
  isMobile?: boolean;
}

interface ILocationState {
  focusInput: boolean | undefined;
}

export const GlobalSearch: React.FC<IGlobalSearchProps> = ({
  component,
  isMobile = false,
}) => {
  const [focused, setFocused] = useState<boolean>(isMobile);
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const shouldFocusInput = location.state?.focusInput;
  const { t } = useTranslation();
  const query = UrlHelper.parse(
    history.location.search,
  ) as SearchScreenQueryParams;
  const suggestions = useSelector(
    (state: IAppState) => state.media.mediaSearch.Suggestions,
  );
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    IMediaSearchSuggestionModel[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const pickedFilters = [MediaSuggestionType.Phrase];

  const suggestionsContainerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(suggestionsContainerRef, () => setShowSuggestions(false));

  useEffect(() => {
    pickedFilters.map((filter) => {
      const filterSuggestions =
        suggestions?.filter((suggestion) => suggestion.TypeCode === filter) ||
        [];
      setFilteredSuggestions((prevFilteredSuggesitons) => [
        ...prevFilteredSuggesitons,
        ...filterSuggestions,
      ]);
    });
  }, [suggestions]);

  useEffect(() => {
    if (shouldFocusInput) {
      history.replace({ pathname: ROUTES.SEARCH_SCREEN });
    }
  }, [shouldFocusInput, history]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredSuggestions([]);
    setShowSuggestions(true);
    e.preventDefault();

    if (location.pathname === ROUTES.SEARCH_SCREEN) {
      history.replace({
        pathname: ROUTES.SEARCH_SCREEN,
        search: UrlHelper.joinQueries(history.location.search, {
          query: e.target.value,
          category: undefined,
          suggestion: undefined,
        }),
      });
    } else if (e.target.value.length >= 3) {
      history.push({
        pathname: ROUTES.SEARCH_SCREEN,
        search: UrlHelper.joinQueries({ query: e.target.value }),
      });
    }
  };

  const onSuggestionClick = (value: string | undefined) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    history.replace({
      pathname: ROUTES.SEARCH_SCREEN,
      search: UrlHelper.joinQueries({
        query: value,
        suggestion: true,
      }),
    });
  };

  const renderSuggestions = () => {
    if (showSuggestions && filteredSuggestions.length > 0) {
      return (
        <div
          className="GlobalSearch__Suggestions"
          ref={suggestionsContainerRef}
        >
          {filteredSuggestions?.map((filteredSuggestion, index) => {
            return (
              <div
                key={`Suggestion-${filteredSuggestion.Value}-${filteredSuggestion.Id}-${index}`}
                className="GlobalSearch__SuggestionValue"
                onClick={() => onSuggestionClick(filteredSuggestion.Value)}
              >
                {filteredSuggestion.Value}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={cx("GlobalSearch", {
        "GlobalSearch--value": query.query,
        "GlobalSearch--focused": focused,
      })}
    >
      <label htmlFor="global-search" className="label--hidden">
        {t("SEARCH__INPUT_LABEL")}
      </label>
      <Input
        id="global-search"
        allowClear={true}
        autoFocus={!!query.query || shouldFocusInput}
        value={query.query}
        placeholder={t("SEARCH__INPUT_PLACEHOLDER")}
        suffix={
          !isMobile && (
            <img
              alt={t("MENU_SEARCH")}
              className="Input__icon"
              src={component?.IconUrl}
            />
          )
        }
        onSuffixClick={() => {
          if (location.pathname !== ROUTES.SEARCH_SCREEN) {
            history.push({
              pathname: ROUTES.SEARCH_SCREEN,
              state: { focusInput: true },
            });
          }
        }}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => (isMobile ? null : setFocused(false))}
      />
      {renderSuggestions()}
    </div>
  );
};
